<template>
  <div class="banner">
    <div class="article-content">
      <swiper class="swiper" :options="swiperOption" ref="mySwiper">
        <swiper-slide class="swiper-slide">
          <div class="card" @click="$router.push('/ourBusiness')">
            <div class="imgs">
              <img class="card-img" src="../assets/banner01.png" alt />
            </div>

            <div class="content">
              <span class="card-num">01</span>
              <span class="card-tit">虚拟现实仿真技术</span>
              <span class="card-desc"
                >以虚拟现实仿真技术为核心，面向K12、高校及职业培训几方面，提供教材虚拟实验内容，虚拟科研实验室，工业机器人及虚拟岗位培训</span
              >
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <div class="card" @click="$router.push('/ourBusiness')">
            <div class="imgs">
              <img class="card-img" src="../assets/banner02.png" alt />
            </div>
            <div class="content">
              <span class="card-num">02</span>
              <span class="card-tit">数字化方向</span>
              <span class="card-desc"
                >以数字化、国产化为切入点，政企信息化系统方向的系统积累与沉淀，推动自身作为首批国产化的系统服务商深耕政企市场。</span
              >
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <div class="card" @click="$router.push('/ourBusiness')">
            <div class="imgs">
              <img class="card-img" src="../assets/banner03.png" alt />
            </div>
            <div class="content">
              <span class="card-num">03</span>
              <span class="card-tit">其他</span>
              <span class="card-desc">物联网等行业原有项目积累。</span>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
// import "swiper/swiper-bundle.css"; //swiper版本为6.0或更高
import "swiper/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  props: ["bannerData"],
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      swiperOption: {
        // swiper配置
        direction: "horizontal", // 滑动方向改为垂直
        slidesPerView: 2,
        slidesOffsetBefore: 50,
        slidesOffsetAfter: 50,
        loop: true, //是否循环
        // effect: "coverflow", //进出动画
        grabCursor: true,
        autoplay: true,
        // slideShadows: true,
        // centeredSlides: true,
        coverflowEffect: {
          //   slideShadows: true, // 页面阴影效果
          rotate: 0, // 旋转的角度
          stretch: 10, // 拉伸   图片间左右的间距和密集度
          depth: 0, // 深度   切换图片间上下的间距和密集度
          modifier: 4, // 修正值 该值越大前面的效果越明显
          slideShadows: false,
        },
        slideClass: "swiper-slide",
        slidePrevClass: "swiper-slide-prev",
        watchSlidesProgress: true,
        touchReleaseOnEdges: true,
        longSwipesRatio: 0.2, //滑动多少就可以滑动
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swiper
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  created() {
    var clientWidth = document.documentElement.clientWidth;
    if (clientWidth < 768) {
      this.swiperOption.width = 550;
      this.swiperOption.slidesOffsetBefore = 30;
      this.swiperOption.slidesOffsetAfter = 10;
    } else if (clientWidth > 768) {
      this.swiperOption.width = 2200;
      this.swiperOption.slidesOffsetBefore = 35;
      this.swiperOption.slidesOffsetAfter = 100;
    }
  },
  mounted() {},
  watch: {},
};
</script>
<style scoped  lang="scss">
.card {
  border: 0;
}
.banner {
  width: 100%;
  .article-content {
    width: 100%;
    .swiper {
      width: 100%;
      .swiper-slide {
        width: 100%;
        .card {
          width: 100%;
          position: relative;
          padding: 0 0 1.25rem 0;
          box-shadow: -3px 2px 8px rgb(83 199 251 / 26%);
          .imgs {
            width: 100%;
            img {
              width: 100%;
            }
          }
          .content {
            padding: 40px 30px 20px;
            display: flex;
            align-items: center;
            white-space: nowrap;
            .card-num {
              color: #333333;
              font-size: 1.25rem;
              // width: 2rem;
            }

            .card-tit {
              position: relative;
              padding: 0 1.2rem;
              color: #333333;
              font-size: 1.25rem;
              // &::before {
              //   content: "";
              //   position: absolute;
              //   right: 0.9375rem;
              //   bottom: -1.875rem;
              //   width: 2.5rem;
              //   height: 1.25rem;
              //   background: url("../assets/lujin.png") no-repeat;
              // }
            }
            .card-desc {
              color: #999999;
              font-size: 1rem;
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              // padding: 0 1.3rem;
              height: 1.375rem;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .banner {
    width: 100%;
    .article-content {
      width: 100%;
      .swiper {
        width: 100%;
        .swiper-slide {
          width: 100%;
          .card {
            padding: 0 0 0.25rem 0;
            .content {
              padding: 0.5rem 0.5rem;
              .card-tit {
                padding: 0 0.3rem;
                &::before {
                  display: none;
                }
              }
              .card-tit,
              .card-num {
                font-size: 0.8rem;
              }
              .card-desc {
                height: 0.875rem;
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>